.container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  min-width: 22px;
  height: 22px;
  border: 2px solid #949494;
  border-radius: 5px;
}

.label.active {
  background-color: #00d1d2;
  border-color: #00d1d2;
}

.input {
  display: none;
}
