.page {
  background-image: url('../../images/bg.png');
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 14px;
  margin: 14px;
  padding: 25px 16px;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  padding-top: 35%;
}

.title {
  font-family: IQOS;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #34303d;
}

.text {
  font-family: IQOS;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #34303d;
}

.button {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 auto;
  width: fit-content;
  border-radius: 24px;
  padding: 16.5px 50px;
  border: none;
  background-color: #34303d;
  font-family: IQOS;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #ffffff;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
