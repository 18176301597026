.page {
  background-image: url('../../images/bg.png');
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 14px;
  margin: 14px;
  padding: 25px 16px;
  border-radius: 24px;
}

.title {
  font-family: IQOS;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #34303d;
}

.text {
  font-family: IQOS;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #34303d;
}
.button {
  width: 100%;
  padding: 10px;
  border-radius: 24px;
  border: none;
  background-color: #12d1d8;
  font-family: IQOS;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.bottomText {
  font-family: IQOS;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #959595;
}
